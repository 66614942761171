<template>
    <div class="comment-wrapper">
        <h3>Leave Your Comment</h3>
        <div class="comment-form">
            <form action="#">
                <div class="row row-10">
                    <div class="col-md-6 col-12 section-space--bottom--20">
                        <input type="text" placeholder="Your Name">
                    </div>
                    <div class="col-md-6 col-12 section-space--bottom--20">
                        <input type="email" placeholder="Your Email">
                    </div>
                    <div class="col-12">
                        <textarea placeholder="Your Message"></textarea>
                    </div>
                    <div class="col-12">
                        <input type="submit" value="Send Comment">
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {

    };
</script>